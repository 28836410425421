import { httpGet, httpPost, httpPut, toQueryString } from '../Service';

//자산 리스트 조회
export function getAssetList(data) {
	return httpGet('/api/v1.0/cis/rental/asset' + toQueryString(data));
}

//자산 스펙 조회
export function getAssetSpec(data) {
	return httpGet('/api/v1.0/cis/rental/asset-spec' + toQueryString(data));
}

//자산정보 변경
export function updateAssetInfo(data) {
	data = data || {};
	return httpPut('/api/v1.0/cis/rental/asset/' + data.asstSeq, { body: data });
}

//자산정보 변경 (리스트)
export function updateAssetListInfo(data) {
	data = data || {};
	return httpPut('/api/v1.0/cis/rental/asset', { body: data });
}


//자산변경내역 조회
export function getAssetHistory(data) {
	return httpGet('/api/v1.0/cis/rental/asset-history' + toQueryString(data));
}

//반납신청
export function postAssetReturnAsk(data) {
	data = data || {};
	return httpPost('/api/v1.0/cis/rental/asset-return', { body: data });
}

//반납신청수정
export function updateAssetReturnAsk(data) {
	data = data || {};
	return httpPut('/api/v1.0/cis/rental/asset-return/' + data.returnAskNo, { body: data });
}

//반납신청리스트조회
export function getAssetReturnAskList(data) {
	return httpGet('/api/v1.0/cis/rental/asset-return' + toQueryString(data));
}

//만기자산리스트조회
export function getExpAssetList(custNo) {
	return httpGet('/api/v1.0/rental/asset/' + custNo + '/expiry');
}

//만기자산연장배치 처리
export function beginAssetExtendBatch(param) {
	return httpGet('/api/v1.0/cis/rental/asset/extend' + param);
}

//mpc자산관리조회
export function getCustAssetList(param) {
	return httpGet('/api/v1.0/cis/rental/asset/mpc/' + param.custNo + toQueryString(param));
}

//mpc자산상세저장
export function saveCustAssetList(data) {
	return httpPost('/api/v1.0/cis/rental/asset/mpc', {body: data});
}

//반납신청상세정보
export function getAssetReturnAskInfo(returnAskNo) {
	return httpGet('/api/v1.0/cis/rental/asset-return/' + returnAskNo);
}

//반납신청자산정보
export function getReturnAssetInfo(returnAskNo) {
	return httpGet('/api/v1.0/cis/rental/asset-return/item/' + returnAskNo);
}

//반납신청리스트조회(수기)
export function getAssetReturnAskManualList(data) {
	return httpGet('/api/v1.0/cis/rental/asset-return-manual' + toQueryString(data));
}

//반납신청수정(수기)
export function updateAssetReturnAskManual(data) {
	data = data || {};
	return httpPut('/api/v1.0/cis/rental/asset-return-manual/' + data.apiInputSeq, { body: data });
}

//승계목록조회
export function getTransAssetList(data) {
	return httpGet('/api/v1.0/cis/rental/trans-asset' + toQueryString(data));
}

//자산승계요청
export function requestAssetTrans(data) {
	return httpPost(`/api/v1.0/cis/rental/asset/${data.asstSeq}/trans`, { body: data });
}

//자산승계수정
export function updateAssetTrans(data) {
	data = data || {};
	return httpPut(`/api/v1.0/cis/rental/trans-asset/${data.chgAskNo}`, { body: data });
}

//자산승계취소
export function cancelAssetTrans(data) {
	data = data || {};
	return httpPost(`/api/v1.0/cis/rental/trans-asset/${data.chgAskNo}/cancel`, { body: data });
}

//자산승계처리
export function procAssetTrans(data) {
	data = data || {};
	return httpPost(`/api/v1.0/cis/rental/trans-asset/${data.chgAskNo}/process`, { body: data });
}

//자산승계접수
export function acceptAssetTrans(data) {
	data = data || {};
	return httpPost(`/api/v1.0/cis/rental/trans-asset/${data.chgAskNo}/accept`, { body: data });
}

//자산상태관리조회
export function getCustAssetStatusManagerList(param) {
	return httpGet(`/api/v1.0/cis/rental/cust/${param.custNo}/asset/status-manager${toQueryString(param)}`);
}

//자산상태상세저장
export function saveCustAssetStatusManagerList(param) {
	return httpPost(`/api/v1.0/cis/rental/asset/status-manager`, {body: param});
}

export function assetReturnAsk(param) {
	return httpPut(`/api/v1.0/cis/rental/asset-return`, {body: param});
}

export function updateBulkReturnAskInfoList(param) {
	return httpPut(`/api/v1.0/cis/rental/asset-return/info`, {body: param});
}